import _objectSpread from "/Users/pushred/dev/global-av-survey/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import listify from 'listify';
import { intersection, flatten, shuffle, sortBy, values } from 'lodash';
import { CLOSE_NAV, GET_CONTENT, OPEN_NAV, SET_CITY, SET_MAP, SET_NAV_OFFSET, SET_TAG, SET_ZOOM } from './actionTypes';

var converter = require('number-to-words');

var pluralize = require('pluralize');

export default function reduce() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case GET_CONTENT:
      var content = action.payload;
      if (!content.cities) return content;
      content.lastModified = content.cities.lastModified; // flatten geojson data

      content.cities = content.cities.features.reduce(function (flat, city) {
        flat[city.properties.id] = _objectSpread({}, city.properties, {
          coordinates: city.geometry.coordinates
        });
        return flat;
      }, {}); // add city lists to each category

      content.categories = Object.keys(content.categories).reduce(function (categories, key) {
        var category = content.categories[key];
        category.cities = values(content.cities).filter(function (city) {
          return category.id === city.category;
        }).map(function (city) {
          return city.id;
        });
        categories[key] = category;
        return categories;
      }, {}); // convert tag mappings to arrays for iteration

      content.tags = Object.keys(content.tags).reduce(function (tags, key) {
        var categoryTags = Object.keys(content.tags[key]).map(function (tagKey) {
          var id = content.tags[key][tagKey].id.toLowerCase(); // normalize

          return {
            id: id,
            category: key,
            title: tagKey,
            short: content.tags[key][tagKey].short,
            summary: content.tags[key][tagKey].summary,
            cities: values(content.cities).filter(function (city) {
              return city.tags.includes(id);
            }).map(function (city) {
              return city.id;
            }).sort()
          };
        });
        tags[key] = categoryTags;
        return tags;
      }, {});
      return content;

    case SET_CITY:
      return cityReducer(state, action.payload);

    case SET_MAP:
      return _objectSpread({}, state, {
        hasMap: true
      });

    case SET_NAV_OFFSET:
      return _objectSpread({}, state, {
        navOffset: action.payload
      });

    case SET_TAG:
      return tagReducer(state, action.payload);

    case SET_ZOOM:
      return _objectSpread({}, state, {
        zoomLevel: action.payload
      });

    case OPEN_NAV:
      return _objectSpread({}, state, {
        isNavOpen: true
      });

    case CLOSE_NAV:
      return _objectSpread({}, state, {
        isNavOpen: false
      });

    case '@@router/LOCATION_CHANGE':
      if (action.payload.hash) return state;
      if (document.scrollingElement) document.scrollingElement.scrollTop = 0;
      var zoomLevel = action.payload.pathname === '/' ? 'fitBounds' : null;
      return _objectSpread({}, state, {
        zoomLevel: zoomLevel,
        city: null,
        hasMap: false,
        isNavOpen: false,
        navOffset: null,
        similarCities: null,
        tag: null
      });

    default:
      return state;
  }
} // city reducer

function cityReducer(state, currentCityId) {
  var policyCategory = Object.keys(state.tags).find(function (c) {
    return /policy/i.test(c);
  });
  var policyTags = state.tags[policyCategory].map(function (t) {
    return t.id;
  });
  var currentCity = state.cities[currentCityId];
  var currentCityPolicyTags = currentCity.tags.filter(function (id) {
    return policyTags.includes(id);
  }); // get more like this

  var similarCities = shuffle(Object.keys(state.cities)).reduce(function (accumulator, cityId) {
    if (cityId === currentCityId) return accumulator;
    var city = state.cities[cityId];
    var matchingPolicyTags = intersection(city.tags, currentCityPolicyTags);
    var similarity = matchingPolicyTags.length;
    var isQuartileMatch = city.quartile === currentCity.quartile;
    var policyTagTitles;
    var policySummary;

    if (matchingPolicyTags.length) {
      policyTagTitles = matchingPolicyTags.map(function (id) {
        return state.tags[policyCategory].find(function (tag) {
          return tag.id === id;
        });
      }).map(function (tag) {
        return tag && "<strong>".concat(tag.title, "</strong>");
      });
      policySummary = intersection(city.tags, policyTags).length > 1 ? "".concat(listify(policyTagTitles).toLowerCase(), " and ").concat(converter.toWords(intersection(city.tags, policyTags).length - 1), " other ").concat(pluralize('area', intersection(city.tags, policyTags).length - 1)) : "".concat(listify(policyTagTitles).toLowerCase());
    } else if (isQuartileMatch) {
      policyTagTitles = city.tags.map(function (id) {
        return state.tags[policyCategory].find(function (tag) {
          return tag.id === id;
        });
      }).map(function (tag) {
        return tag && "<strong>".concat(tag.title, "</strong>");
      }).filter(Boolean);
      policySummary = "".concat(listify(policyTagTitles).toLowerCase());
    }

    var similarCityData = {
      id: city.id,
      name: city.name,
      category: city.category,
      region: city.region,
      countryCode: city.countryCode,
      similarity: similarity,
      policySummary: policySummary
    };
    if (similarity) accumulator.byPolicy.push(similarCityData);
    if (isQuartileMatch) accumulator.bySize.push(similarCityData);
    return accumulator;
  }, {
    byPolicy: [],
    bySize: []
  });
  return _objectSpread({}, state, {
    city: currentCity,
    similarCities: {
      byPolicy: sortBy(similarCities.byPolicy, 'similarity').slice(0, 4).reverse(),
      bySize: similarCities.bySize.slice(0, 4)
    },
    zoomLevel: null
  });
} // tag reducer


function tagReducer(state, tagId) {
  var tags = flatten(values(state.tags));
  return _objectSpread({}, state, {
    city: null,
    similarCities: null,
    tag: tags.find(function (t) {
      return t.id === tagId;
    }),
    zoomLevel: null
  });
}