import _defineProperty from "/Users/pushred/dev/global-av-survey/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "/Users/pushred/dev/global-av-survey/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import { SET_INTERACTIVE_EL, SET_WAYPOINT } from './actionTypes';
export default function reduce() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_INTERACTIVE_EL:
      return _objectSpread({}, state, {
        interactiveEl: action.payload
      });

    case SET_WAYPOINT:
      return _objectSpread({}, state, {
        waypoints: _objectSpread({}, state.waypoints, _defineProperty({}, action.payload.name, action.payload.position.currentPosition))
      });

    default:
      return _objectSpread({}, state, {
        waypoints: {}
      });
  }
}